import React from 'react'

import Layout from '../components/layout'
import Welcome from '../components/welcome'

export default ({ data }) => (
  <Layout>
    <Welcome />
  </Layout>
)
