import React from 'react'
import styled from 'styled-components'
import photo from '../images/welcome.jpg'

export default () => {
    return (
        <>
            <Welcome>
                <Me
                    src={photo}
                    alt="Piotr Baran"
                />
                <div>
                    <p>Jestem testerem oprogramowania i trenerem.</p>
                    <p>Cieszę się, że jesteś na mojej stronie. Mam nadzieję, że znajdziesz tu coś dla siebie. 
                    Jeśli nie - napisz do mnie - razem na pewno coś wymyślimy!</p>
                </div>
            </Welcome>
        </>
    )
}

const Welcome = styled.div`
    display: grid;
    align-items: center;
    font-size: 1.5rem;

    @media (min-width: 768px) {
        grid-template-columns: 2fr 1fr;
        p {
            margin: 0 5px;
            padding: 0.5rem 2rem
        }
    }
`

const Me = styled.img`
    grid-column: 2 / -1;
    grid-row-end: -1;
    justify-self: end;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border-radius: 1%;
`
